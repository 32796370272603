.container-doacao{
    display: flex;
    flex-flow: row wrap;
    width: 90%;
    margin: 4% 5% 4% 5%;
}

.container-doacao h1, .container-doacao h2, .container-doacao h3{
    margin: 0;
    margin-bottom: 1%;
    width: fit-content;
}

.container-doacao h1{
    font-size: 30px;
}

.container-introducao-doacao{
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 5%;
    width: 100%;
}

.container-introducao-doacao-conteudo{
    width: 46%;
    margin-right: 4%;
}

.container-introducao-doacao img{
    width: 45%;
    height: max-content;
}

.container-doacao-brasil{
    display: flex;
    flex-flow: column wrap;
    padding: 40px 50px 40px 50px;
    border-radius: 11px;
    background-color: #dfdede;
}

.container-doacao-brasil-introducao{
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 4%;
}

.container-doador-doacao{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin-bottom: 4%;
    padding: 30px;
    border-radius: 11px;
    background-color: rgb(242, 242, 242);
}

.container-doador-doacao-conteudo{
    display: flex;
    flex-flow: column wrap;
    width: 55%;
    margin-right: 5%;
}

.container-info-doador-doacao{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    background-color: #020381 ;
    width: 40%;
    color: #F6F6F6;
    padding: 20px 25px 15px 25px;
    font-size: 13px;
    border-radius: 11px;
}

.container-processo-doacao{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 30px;
    border-radius: 11px;
    background-color: rgb(242, 242, 242);
}

.container-processo-doacao img{
    width: 35%;
    height: max-content;
    margin-right: 5%;
}

.container-processo-doacao-conteudo{
    display: flex;
    flex-flow: column wrap;
    width: 60%;
}

.container-conclusao-doacao{
    font-size: 20px;
    font-weight: 700;
    color: #020381 ;
}

.container-fonte{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.container-fonte a{
    margin: 16px;
    margin-left: 5px;
    margin-right: 5px;
    color: #020381;
}